/* background.css */
.hero-background {
    background-image: url('https://maaltjidassets.s3.af-south-1.amazonaws.com/Background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  