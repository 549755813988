.GMap {
    height: 70vh;
    width: 70vw;
  }

.map {
    height: 100px !important;
    width: 100px  !important;
  }
  
  .map-container {
    height: 100%;
    width: 100%;
    
  @media screen and (max-width: 768px) {
    .map-container {
      width: 70vw;
    }
  }

  @media screen and (max-width: 480px) {
    .map-container {
      width: 80vw;
    }
    /* .autocompleteInput{
      width:100%;
      border-bottom: 10px;
    } */
  }
  }

/* 
.autocompleteInput{
  width: 90%;
  border-bottom: 10px;
} */