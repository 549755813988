/* .homelayout {
    top: 70px;
    height: calc(100vh - 130px);
    overflow-y: auto;
    scrollbar-width: thin; 
    scrollbar-color: #888 #f1f1f1; 
    margin-right: auto;
  margin-left:auto;
} */

.homelayout {
  position: absolute; /* Ensures the positioning is relative to the viewport */
  top: 70px; /* Adjust based on the header's height */
  left: 0;
  right: 0;
  height: calc(100dvh - 130px); /* 100vh minus the total height of header and footer */
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
  padding: 0; /* Remove padding if present */
  margin: 0 auto; /* Center the content horizontally */
}

@media screen and (max-width: 768px) {
  .homelayout {
    /* Adjustments for smaller screens if needed */
    top: 70px; /* Or adjust if the header's height changes */
    height: calc(100dvh - 130px); /* Ensuring it still fits between header and footer */
  }
}


/* Webkit browsers (Chrome, Safari) */
.homelayout::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .homelayout::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  .homelayout::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
    border: 2px solid #f1f1f1; /* Space around the scrollbar thumb */
  }
  
  .homelayout::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb when hovered */
  }

.welcomebanner {
    top: 70px;
}

.home_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 20px;
    text-align: center;
    max-width: 900px;

}
.card-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
}
.card-content {
    font-size: 1.1em;
    color: #000D29;
}

.rowitem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
}