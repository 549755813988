.cart__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.639);
    z-index: 99999;
  }
  
  .cart {
    position: absolute;
    top: 70px;
    right: 0;
    width: 100vw;
    height: calc(100dvh - 130px) !important;
    background: #fff;
    z-index: 999999;
  }
  
  .deliveryfeeprice {
    font-size: 0.9rem;
    font-weight: 600;
    color: #dbab27;
  }
  
  .cart__item-list {
    height: calc(100dvh - 200px);
    /* padding-bottom: 70px;
    overflow-y: auto; */
  }
  
  .cart__item-list > h6 {
    margin-top: 5rem;
  }
  
  .cart__bottom {
    /* position: absolute;
    bottom: 0;
    left: 0; */
    padding-bottom: 60px;
    padding: 5px 20px;
    width: 100%;
    height: 70px;
    background: #000D29 !important;;
  }
  
  .cart__bottom h6 {
    font-size: 1rem;
    color: #fff;
  }
  
  .cart__bottom h6 span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  
  .cart__bottom button {
    border: none;
    padding: 4px 20px;
    border-radius: 5px;
    background: #fff;
    color: #212245;
  }
  
  .cart__bottom button a {
    color: #212245;
    font-weight: 600;
  }
  
  @media only screen and (min-width: 870px) {
    .cart {
      width: 100%;
    }
  }

  .cart__closeButton {
    font-size: 3rem;
    background: #212245;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    width: fit-content;
    height: 60px;
    padding: 10px 20px;
    position: absolute;
    top: 0.5rem;
    right: 0rem;
    margin: 0.5rem 0;
    display: none;
  }

  .deliveryfee_item{
    padding: 0 5px;
  }

  .messageCard{
    padding: 0 15px; /* Add padding to the container */
  }

  .shopping_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 15px;
    text-align: center;
    max-width: 900px;
    margin-bottom: 15px;
  }
  .card-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  .card-content {
    font-size: 1.1em;
    color: #000D29;
  }
  
  .rowitem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
  }

  .cartdetails__item-list {
    /* height: calc(100vh - 400px); */
    /* padding-bottom: 70px; */
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */
    /* margin-right: auto;
    margin-left:auto; */
    max-width: 900px;
    padding: 0 5px; /* Add padding to the container */
    margin: 0 auto;
  
  }

  .cartdetails__page{
    height: calc(100dvh - 130px);
    overflow-y: hidden;
  }
  
  
  /* Webkit browsers (Chrome, Safari) */
  .cartdetails__item-list::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .cartdetails__item-list::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  .cartdetails__item-list::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
    border: 2px solid #f1f1f1; /* Space around the scrollbar thumb */
  }
  
  .cartdetails__item-list::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb when hovered */
  }
  
  
  .cartdetails__item-list > h6 {
    margin-top: 5rem;
  }

  .notification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .blurred-background {
    filter: blur(5px);
  }

  .error-message {
    color: red;
    font-size: 0.9em;
  }

  .success-message {
    color: #198754;
    font-size: 0.9em;
  }

  
  /* .cart__closeButton {
    width: fit-content;
    height: 60px;
    padding: 10px 20px;
    position: absolute;
    top: 0.5rem;
    right: 0rem;
    margin: 0.5rem 0;
    display: none;
  }
  
  .cart__closeButton span i {
    font-size: 1.1rem;
    background: #212245;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
  } */