.product__item {
    border: 1px solid #fde4e4;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    height: 100%;
    min-height: 17rem;
    border-radius: 8px;
    /* background-color: #212245; */
    background-color: #000D29 ;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    /* background-color:#000D29; */
  }
  
  .product__img {
    margin-bottom: 2px;
    transition: 0.4s;
  }
  
  .product__img:hover {
    transform: scale(1.2);
  }
  
  .product__content h5 a {
    color: #212245;
    font-style: 1rem;
  }
  
  .product__content h5 {
    margin-bottom: 2px;
  }
  
  .product__price {
    font-weight: 500;
    font-size: 1.2rem;
    /* color: #a64324; */
    color:#fff
  }
  
  .addTOCART__btn {
    border: none;
    padding: 2px 15px;
    /* background: #183f4e; */
    background: #DAA927;
    color: #fff;
    border-radius: 5px;
    font-size: 1.5rem;
    height: ;
  }

  .meal_name {
    font-weight: 600; 
    font-size: 1.1rem !important;
    /* color: #212245 ; */
    color: #fff !important;
  }

  .reviews {
    font-weight: 400; 
    font-size: 0.8rem ;
    /* color: #212245 ; */
    color: #fff ;
  }

  .rating {
    font-weight: 600; 
    font-size: 0.8rem ;
    /* color: #212245 ; */
    color: #fff ;
  }
  
  @media only screen and (max-width: 992px) {
    .product__content h5 a {
      font-size: 0.8rem;
    }
    .product__item {
      padding: 5px;
    }
    .addTOCart__btn {
      padding: 5px 15px;
      /* font-size: 0.7rem; */
      font-size: 1rem;
    }
  }

@media only screen and (max-width: 396px) {
  .addTOCART__btn {
    padding: 1px 5px;
  }
}

@media only screen and (max-width: 352px) {
  .addTOCART__btn {
    /* font-size: 0.7rem; */
    font-size: 1rem;
  }
}

@media only screen and (min-width: 342px) {
  .card-image-container {
    width: 100%; /* Matches the width of the card */
    height: 41vw; /* Set the height as a fraction of the screen width */
    max-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any overflow for aspect ratio maintenance */
  }
}

@media only screen and (max-width: 575px) {
  .card-image-container {
    width: 100%; /* Matches the width of the card */
    height: 45vw; /* Set the height as a fraction of the screen width */
    max-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any overflow for aspect ratio maintenance */
  }
}

@media only screen and (min-width: 576px) and (max-width: 993px) {
  .card-image-container {
    width: 100%; /* Matches the width of the card */
    height: 45vw; /* Set the height as a fraction of the screen width */
    max-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any overflow for aspect ratio maintenance */
  }
}

@media only screen and (min-width: 994px) and (max-width: 1199px) {
  .card-image-container {
    width: 100%; /* Matches the width of the card */
    height: 18vw; /* Set the height as a fraction of the screen width */
    max-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any overflow for aspect ratio maintenance */
  }
}

@media only screen and (min-width: 1200px) {
  .card-image-container {
    width: 100%; /* Matches the width of the card */
    height: 20vw; /* Set the height as a fraction of the screen width */
    max-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any overflow for aspect ratio maintenance */
  }
}



.product-image {
  width: 100%; /* Ensure image takes full container width */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Crop the image to fit the container while keeping the aspect ratio */
}

.product__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body-content {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between elements */
}

.rating-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Add spacing between elements */
}


.modal-body-overflow {
  max-height: 80dvh; /* Limit the height of the modal body */
  overflow-y: auto; /* Enable vertical scrolling for overflowing content */
}


.review__item {
  margin-bottom: 15px;
  border-bottom: 1px solid #fde4e4;
}

.reviewitem {
  /* width: 300px; */
  border: 1px solid #000D29;
  background-color: #DCDCDC !important;
  border-radius: 8px !important;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  width: 100%;
  max-width: 900px;
  margin-bottom: 10px 0;
  margin-top: 20px;

  /* border-bottom-left-radius:8px !important;
  border-bottom-right-radius: 8px  !important; */
  margin-left: auto;
  margin-right: auto;
}
  
.review_words {
  font-size: 0.8rem;
  margin-bottom: 0px !important;
}

.review_words span {
  font-size: 0.9rem;
  font-weight: 600;
  color: #dbab27;
}