.reviewheader{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .review__page{
    height: calc(100dvh - 130px);
    overflow-y: hidden;
  }

  .pagination-review {
    height: 25px !important;
    margin-bottom: 70px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
     /* Style for the order item list container */
.review__page-list {
  margin-right: auto;
margin-left:auto;
max-width: 900px;

}

.pagination-small-button {
  height: 30px; /* Adjust the height as desired */
  padding: 0.25rem 0.5rem; /* Adjust padding for smaller look */
}

.review__tab-header {
  height: 40px;

}

   /* Style for the order item list container */
.review__item-list {
  height: calc(100dvh - 280px);
  padding-bottom: 10px;
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
  margin-right: auto;
margin-left:auto;
max-width: 900px;

}

/* Webkit browsers (Chrome, Safari) */
.review__item-list::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.review__item-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.review__item-list::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 2px solid #f1f1f1; /* Space around the scrollbar thumb */
}

.review__item-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}


.review__item-list > h6 {
  margin-top: 5rem;
}

.review_item {

}

.review_item {
  /* width: inherit; */
  border: 1px solid #000D29;
  background-color: #DCDCDC !important;
  border-radius: 8px !important;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
  /* margin-top: 10px; */
  margin-bottom: 10px 0;
  width: 98%;
  max-width: 900px;

  /* border-top-left-radius:8px !important;
  border-top-right-radius: 8px  !important;

  border-bottom-left-radius:8px !important;
  border-bottom-right-radius: 8px  !important; */
}

.review_item:hover {
  cursor: default;
}

.review_item {
  margin-bottom: 15px;
  border-bottom: 1px solid #fde4e4;
}

.review_item:hover {
  background-color: rgba(230, 230, 230, 0.994);
  cursor: pointer;
}
