.precheckout_body {

    text-align: left;
    max-width: 900px;
    height: calc(100dvh - 200px);
    overflow-y: auto;

}

.promocode {
    text-align: center;
    max-width: 300px;
    
}

.walletcard-content{
    background-color: lightgrey;
    border-radius: 5px;
}